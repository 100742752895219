// extracted by mini-css-extract-plugin
export var action_content = "collection-module--action_content--8Vblj";
export var column = "collection-module--column--gd57x";
export var column_constrain = "collection-module--column_constrain--1fFYn";
export var column_fill = "collection-module--column_fill--kFv0i";
export var column_minimum = "collection-module--column_minimum--EHDvu";
export var content = "collection-module--content--7Srw5";
export var content_container = "collection-module--content_container--p1-A5";
export var content_wrapper = "collection-module--content_wrapper--ueQ-c";
export var detail_container = "collection-module--detail_container--h8QzH";
export var left_area = "collection-module--left_area--NpKNT";
export var profile_image = "collection-module--profile_image--MS9nK";
export var right_area = "collection-module--right_area--W2HxM";
export var social_icon = "collection-module--social_icon--xwqkJ";
export var social_likes_container = "collection-module--social_likes_container--kKFRN";
export var social_likes_count = "collection-module--social_likes_count--RQc7b";
export var social_link_container = "collection-module--social_link_container--a+Q2H";