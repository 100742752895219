import React from "react";
import * as styles from "./collection.module.css";
import { withAuthentication } from "../../hoc/withAuthentication";
import Main from "../../components/main";
import ContentArea from "../../components/contentArea";
import TextArea from "../../components/textArea";
import Button from "../../components/button";
import ValueRow from "../../components/valueRow";
import DisplayName from "../../components/displayname";
import MasonryTiles from "../../components/masonryTiles";
import ScreenLoad from "../../components/screenLoad";
import SearchLink from "../../components/searchLink";
import LinkWithIcon from "../../components/linkWithIcon";
import SocialButton from "../../components/socialButton";
import NotFoundPage from "../../components/notFoundPage";
import Statistics from "../../components/statistics";
import TranslationParagraph from "../../components/translationParagraph";
import Tile from "../../components/tile";
import Consts from "../../config/consts";
import Api from "../../config/api";
import Common from "../../config/common";
import { faSpinner, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faFlag, faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faShareAlt, faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class Collection extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();

            // Grab the query params from the url
            //const params = new URLSearchParams(props.location.search);
            this.state = {
                windowSize: window.innerWidth,
                initialising: true,
                user: props.auth.user || null,
                collectionId: props.params.collection_id,
                collection: null,
                isLiked: false,
                likeCount: 0,
            };

            Api.market({
                endpoint: "/content/collection",
                method: "GET",
                data: {
                    collectionId: props.params.collection_id,
                },
            })
                .then((res) => {
                    this.setState({
                        initialising: false,
                        collection: res.data && Object.keys(res.data).length > 0 ? res.data : null,
                        likeCount: Common.objectPropertyExists(res.data, "likeCount", 0),
                    });
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                        collection: null,
                        likeCount: 0,
                    });
                });
        }

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            // Make a call to fetch our current authed users like for this media
            if (this.props.auth.user) {
                Api.market({
                    endpoint: "/content/collection/like",
                    method: "GET",
                    data: {
                        collectionId: this.state.collectionId,
                    },
                })
                    .then((like_arr) => {
                        if (like_arr && like_arr.hasOwnProperty(this.state.collectionId) && like_arr[this.state.collectionId] === true) {
                            this.setState({
                                isLiked: true,
                            });
                        }
                    })
                    .catch((e) => {});
            }
        }

        onLike = () => {
            const old_liked = this.state.isLiked;
            const old_like_count = this.state.likeCount;

            this.setState(
                {
                    isLiked: !old_liked,
                    likeCount: old_liked ? old_like_count - 1 : old_like_count + 1,
                },
                () => {
                    Api.market({
                        endpoint: "/content/collection/like",
                        method: "PATCH",
                        data: {
                            value: this.state.isLiked ? "LIKED" : "UNLIKED",
                            collectionId: this.state.collectionId,
                        },
                    })
                        .then((res) => {
                            // Done - no need to do anything here
                        })
                        .catch((e) => {
                            // Failed - undo our local changes
                            this.setState({
                                isLiked: old_liked,
                                likeCount: old_like_count,
                            });
                        });
                }
            );
        };

        refreshPage = () => {
            window.location.reload();
        };

        onShowBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };

        render() {
            return !this.state.collection ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Collection"} />
                </Main>
            ) : (
                <Main
                    ref={this.main_ref}
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea extraTopPadding={true}>
                        <div className={styles.detail_container}>
                            <div className={styles.left_area}>
                                <div className={styles.content}>
                                    <TextArea>
                                        <h1>{this.state.collection.name}</h1>
                                        <div className={styles.social_likes_container}>
                                            <FontAwesomeIcon title="Like" icon={this.state.isLiked ? faHeartSolid : faHeart} className={styles.social_icon} onClick={this.onLike} />
                                            <span className={styles.social_likes_count}>{this.state.likeCount} Likes</span>
                                        </div>
                                    </TextArea>
                                    <TextArea>
                                        <TranslationParagraph object={this.state.collection.description} language={"en"} collapsable={true} />
                                    </TextArea>
                                    <TextArea>
                                        <div className={styles.social_link_container}>
                                            {Common.objectPropertyExists(this.state, "collection.socialLinks", []).map((link, index) => (
                                                <SocialButton key={`social_sect_${index}`} light={false} link={link} />
                                            ))}
                                        </div>
                                    </TextArea>
                                    {!!this.state.collection.genres && this.state.collection.genres.length > 0 && (
                                        <TextArea>
                                            <p>
                                                {Common.categoryUniqueSplit(this.state.collection.genres).map((tag, i) => (
                                                    <SearchLink key={`searchlink_${i}`} term={tag} />
                                                ))}
                                            </p>
                                        </TextArea>
                                    )}
                                    <TextArea>
                                        <ValueRow
                                            title={<DisplayName>{`${this.state.collection.creator.displayName}`}</DisplayName>}
                                            icon={this.state.collection.creator.profileImage ? `${process.env.GATSBY_STORAGE}user/${this.state.collection.creator.profileImage}` : null}
                                            value={"Collection Creator"}
                                            border={false}
                                        />
                                    </TextArea>
                                    {this.state.collection.created && (
                                        <TextArea>
                                            <div className={styles.action_content}>
                                                <LinkWithIcon icon={faPencilAlt} text={"Edit Collection"} to={`/c/edit/${this.state.collection.collectionId}`} />
                                                <LinkWithIcon
                                                    icon={faPlus}
                                                    text={"Add New Media"}
                                                    to={`/m/create?scheme=${this.state.collection.scheme}&collectionId=${this.state.collection.collectionId}`}
                                                />
                                            </div>
                                        </TextArea>
                                    )}
                                </div>
                            </div>
                            <div className={styles.right_area}>
                                <div className={styles.profile_image}>
                                    {Common.objectPropertyExists(this.state, "collection.displayImage", false) && (
                                        <img src={`${process.env.GATSBY_STORAGE}collection/${this.state.collection.displayImage}`} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </ContentArea>
                    <ContentArea extraTopPadding={true} slim={true}>
                        <TextArea>
                            <Statistics
                                stats={{
                                    count: Common.objectPropertyExists(this.state, "collection.contentCount", "-"),
                                    highestSale: Common.objectPropertyExists(this.state, "collection.highestSale", "-"),
                                    floorPrice: Common.objectPropertyExists(this.state, "collection.floorPrice", "-"),
                                    totalVolume: Common.objectPropertyExists(this.state, "collection.totalVolume", "-"),
                                    marketCap: Common.objectPropertyExists(this.state, "collection.marketCap", "-"),
                                    owners: Common.objectPropertyExists(this.state, "collection.owner.count", "-"),
                                }}
                            />
                        </TextArea>
                    </ContentArea>
                    <ContentArea>
                        <MasonryTiles
                            contentEndpoint={"/content/collection/content"}
                            contentEndpointParams={{ collectionId: this.state.collectionId }}
                            windowSize={this.state.windowSize}
                            showExploreMarket={false}
                            onBid={this.onShowBidOverlay}
                        />
                    </ContentArea>
                </Main>
            );
        }
    }
);
