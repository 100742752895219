import React from "react";
import * as styles from "./statistics.module.css";

export default class Statistics extends React.Component {
    constructor(props) {
        super(props);
    }

    displayKey = (key) => {
        let result = "";
        for (let c of key.toString()) {
            if (c === c.toUpperCase()) {
                result += ` ${c}`;
            } else if (result.length === 0) {
                result += c.toUpperCase();
            } else {
                result += c;
            }
        }
        return result;
    };
    render() {
        return (
            <div className={`${styles.container} ${this.props.darkMode ? styles.dark_mode : ""}`}>
                {Object.keys(this.props.stats).map((key, index) => (
                    <div key={`stat_${index}`} className={styles.stat_container}>
                        <span className={styles.value}>{this.props.stats[key] || "-"}</span>
                        <span className={styles.key}>{this.displayKey(key)}</span>
                    </div>
                ))}
            </div>
        );
    }
}
