import React from "react";
import { Link } from "gatsby";
import * as styles from "./linkWithIcon.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class LinkWithIcon extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.to && this.props.to.startsWith("http")) {
            return (
                <a className={`${styles.container}`} target={this.props.openNewTab === true ? "_blank" : "_self"} href={this.props.to}>
                    <p>
                        <span className={styles.icon_container}>
                            <FontAwesomeIcon icon={this.props.icon} className={styles.icon} />
                        </span>
                        <span className={styles.text_container}>{this.props.text}</span>
                    </p>
                </a>
            );
        } else if (this.props.to) {
            return (
                <Link className={`${styles.container}`} to={this.props.to}>
                    <p>
                        <span className={styles.icon_container}>
                            <FontAwesomeIcon icon={this.props.icon} className={styles.icon} />
                        </span>
                        <span className={styles.text_container}>{this.props.text}</span>
                    </p>
                </Link>
            );
        } else if (this.props.onClick) {
            return (
                <span className={`${styles.container}`} onClick={this.props.onClick}>
                    <p>
                        <span className={styles.icon_container}>
                            <FontAwesomeIcon icon={this.props.icon} className={styles.icon} />
                        </span>
                        <span className={styles.text_container}>{this.props.text}</span>
                    </p>
                </span>
            );
        }
    }
}
