import React from "react";
import { Link } from "gatsby";
import * as styles from "./searchLink.module.css";

export default class SearchLink extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Link className={`${styles.container} ${styles.link}`} to={`/search?term=${this.props.term.toLowerCase()}`}>
                <span className={styles.hash}>#</span>
                <span>{this.props.term.toLowerCase()}</span>
            </Link>
        );
    }
}
