import React from "react";
import * as styles from "./translationParagraph.module.css";
import Common from "../config/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

/**
 * TranslationParagraph used to display a translation object directly into a paragraph
 * It also handles collapsing
 */
export default class TranslationParagraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lengthBeforeCollapse: 300,
            text: Common.objectPropertyExists(this.props.object, this.props.language, ""),
            collapsed: true,
        };
    }

    onToggleCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <p className={`${styles.container}`}>
                <span className={`${styles.inner_container} ${this.state.collapsed === false ? styles.expand : ""}`}>
                    {this.state.text.split("\n").map((line, index) => {
                        return !!line && line.length > 0 && <span key={`pl_${index}`}>{line}</span>;
                    })}
                </span>
                {this.state.text.length > this.state.lengthBeforeCollapse && (
                    <span className={styles.collapsor}>
                        <FontAwesomeIcon className={styles.collapse_icon} icon={this.state.collapsed ? faChevronDown : faChevronUp} onClick={this.onToggleCollapse} />
                        {/* <a className={"link"} onClick={this.onToggleCollapse}>

                            {this.state.collapsed ? "Expand" : "Collapse"}
                        </a> */}
                    </span>
                )}
            </p>
        );
    }
}
